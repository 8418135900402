
.system-messages {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  .search-box {
    margin-bottom: 20px;
    display: flex;
  }
}
